import React from 'react';
import { graphql, useStaticQuery } from 'gatsby'
import { StoreProvider, Store } from "../../store/Store";
import SinglePageLayout from "../../components/singlePageLayout"
import { animated } from "react-spring"

const Privacy = () => {
  const {dispatch, state} = Store()

  if(state.mobileMenuIsOpen) dispatch({type: "closeMobileMenu"})

  const data = useStaticQuery(graphql`
      query dataPolicyQuery {
          datoCmsDataPolicy {
              textNode {
                  childMarkdownRemark {
                      html
                  }
              }
          }
      }`
  )
  return (
    <StoreProvider>
      <animated.div>
        <SinglePageLayout previous={{name: "Spoon", link: "/"}} current={{name: "Privacy", link: "/privacy"}} next={{name: "Imprint", link: "/imprint"}}>
          <h1>Privacy</h1>
          <p dangerouslySetInnerHTML={{__html: (data.datoCmsDataPolicy.textNode.childMarkdownRemark.html) || ""}}></p>
        </SinglePageLayout>
      </animated.div>
    </StoreProvider>
  )};

export default Privacy
